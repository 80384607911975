import React, {useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import BottomNav from '../Navigation/BottomNav';
import TopNav from '../Navigation/TopNav';
import {LanguageContext} from '../contexts/LanguageProvider';
import {useFontFamilyByLanguage} from '../hooks/useFontFamilyByLanguage';
import {translate} from '../utils/translate';
import styled from 'styled-components';

// Styled Components
const Container = styled.div`
  font-family: ${props => props.fontFamily};
  text-align: center;
  color: #333;
  padding: 20px;
  background-color: #f1f6f8;
  max-width: 100%;
`;

const HeaderImage = styled.img`
  width: 100%;
  height: auto;
  max-height: 200px;
  object-fit: contain;
`;

const Name = styled.h1`
  font-size: 24px;
  color: #333;
  font-weight: bold;
  margin: 20px 0;
  font-family: ${props => props.fontFamily};
`;

const Description = styled.p`
  font-size: 16px;
  color: #666;
  margin: 10px 0;
  font-family: ${props => props.fontFamily};
`;

const Button = styled.button`
  background-color: #3ddbd1;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  margin: 20px 0;
  cursor: pointer;
  font-family: ${props => props.fontFamily};
`;

const PointsInfo = styled.div`
  background-color: #f0f0f0;
  padding: 10px 20px;
  border-radius: 20px;
  margin: 10px 0;
  font-family: ${props => props.fontFamily};
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
`;

const PartnerPage = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const {fontFamilies} = useFontFamilyByLanguage();
  const {language} = useContext(LanguageContext);
  const [partner, setPartner] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchPartnerDetail = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URI}/api/partner/detail/${id}`,
        );
        setPartner(response.data);
      } catch (err) {
        console.error('Error fetching partner details:', err);
        setError(translate(language, 'partner.error') + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPartnerDetail();
  }, [id, navigate, language]);

  if (loading) {
    return (
      <div>
        <TopNav text={translate(language, 'partner.detail')} />
        <Container fontFamily={fontFamilies.regular}>
          <CloseButton onClick={() => navigate('/')}>&times;</CloseButton>
          <Skeleton height={200} />
          <Name fontFamily={fontFamilies.bold}>
            <Skeleton width={200} />
          </Name>
          <Description fontFamily={fontFamilies.semiBold}>
            <Skeleton count={3} />
          </Description>
          <Skeleton height={40} width={150} />
          <PointsInfo fontFamily={fontFamilies.semiBoldItalic}>
            <p>
              <Skeleton width={100} />
            </p>
            <p>
              <Skeleton width={150} />
            </p>
          </PointsInfo>
          <PointsInfo fontFamily={fontFamilies.semiBoldItalic}>
            <p>
              <Skeleton width={100} />
            </p>
            <p>
              <Skeleton width={150} />
            </p>
          </PointsInfo>
        </Container>
        <BottomNav />
      </div>
    );
  }

  return (
    <div>
      <TopNav text={translate(language, 'partner.detail')} />
      {partner && (
        <Container fontFamily={fontFamilies.regular}>
          <CloseButton onClick={() => navigate('/')}>&times;</CloseButton>
          <HeaderImage src={partner.nameImg} alt="Cover" />
          <Name fontFamily={fontFamilies.bold}>{partner.name[language]}</Name>
          <Description fontFamily={fontFamilies.semiBold}>
            {partner.description[language]}
          </Description>
          <Button
            fontFamily={fontFamilies.regular}
            onClick={() => window.open(partner.webLink, '_blank')}>
            {translate(language, 'partner.shopNow')}
          </Button>
          <PointsInfo fontFamily={fontFamilies.semiBoldItalic}>
            <p>{translate(language, 'partner.basePoints')}</p>
            <p>
              {translate(language, 'partner.pointsInfo').replace(
                '{points}',
                partner.pointsRatio,
              )}
            </p>
          </PointsInfo>
          <PointsInfo fontFamily={fontFamilies.semiBoldItalic}>
            <p>{translate(language, 'partner.extraPoints')}</p>
            <p>
              {translate(language, 'partner.pointsInfo').replace(
                '{points}',
                partner.pointsRatio * 3,
              )}
            </p>
          </PointsInfo>
        </Container>
      )}
      <BottomNav />
    </div>
  );
};

export default PartnerPage;
