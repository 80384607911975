import Skeleton from '@mui/material/Skeleton';
import axios from 'axios';
import {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import BottomNav from '../Navigation/BottomNav';
import TopNav from '../Navigation/TopNav';
import {LanguageContext} from '../contexts/LanguageProvider';
import {useFontFamilyByLanguage} from '../hooks/useFontFamilyByLanguage';
import {translate} from '../utils/translate';
import styled from 'styled-components';

// Styled Components
const Container = styled.div`
  margin-top: 120px;
`;

const ItemContainer = styled.div`
  margin: 20px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ItemRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ItemTitle = styled.div`
  font-size: 12px;
  font-family: ${props => props.fontFamily};
`;

const RedDot = styled.span`
  height: 8px;
  width: 8px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
`;

const ItemDatetime = styled.div`
  font-size: 12px;
  font-family: ${props => props.fontFamily};
  margin-bottom: 10px;
`;

const ItemDescription = styled.div`
  font-size: 12px;
  font-family: ${props => props.fontFamily};
`;

const Notifications = () => {
  const navigate = useNavigate();
  const {language} = useContext(LanguageContext);
  const {fontFamilies} = useFontFamilyByLanguage();

  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchNotifications = async () => {
      setLoading(true);

      try {
        const response = await axios.get(`/api/notification/notifications`);
        setNotifications(response.data.data);
      } catch (error) {
        console.error('Failed to fetch notifications:', error);
        setError(translate(language, 'notifications.fetchError'));
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications();
  }, [navigate, language]);

  const getNotificationTitle = notification => {
    if (language === 'en') return notification.title.en;
    if (language === 'zh') return notification.title.zh;
    if (language === 'zhhk') return notification.title.zhhk;
    return notification.title.en; // Default to English if language not found
  };

  const getNotificationBody = notification => {
    if (language === 'en') return notification.body.en;
    if (language === 'zh') return notification.body.zh;
    if (language === 'zhhk') return notification.body.zhhk;
    return notification.body.en; // Default to English if language not found
  };

  return (
    <>
      <TopNav text={translate(language, 'notifications.myNotifications')} />
      <Container>
        {loading ? (
          <>
            <Skeleton
              variant="rectangular"
              height={80}
              style={{marginBottom: '20px'}}
            />
            <Skeleton
              variant="rectangular"
              height={80}
              style={{marginBottom: '20px'}}
            />
            <Skeleton
              variant="rectangular"
              height={80}
              style={{marginBottom: '20px'}}
            />
          </>
        ) : error ? (
          <p>{error}</p>
        ) : notifications.length > 0 ? (
          notifications.map(notification => (
            <ItemContainer key={notification._id}>
              <ItemRow>
                <ItemTitle fontFamily={fontFamilies.bold}>
                  {!notification.read && <RedDot />}
                  {getNotificationTitle(notification)}
                </ItemTitle>
              </ItemRow>
              <ItemDatetime fontFamily={fontFamilies.light}>
                {new Date(notification.created).toLocaleString('en-US', {
                  month: 'short',
                  day: '2-digit',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </ItemDatetime>
              <ItemDescription fontFamily={fontFamilies.regular}>
                {getNotificationBody(notification)}
              </ItemDescription>
            </ItemContainer>
          ))
        ) : (
          <ItemContainer>
            <p>{translate(language, 'notifications.noNotifications')}</p>
          </ItemContainer>
        )}
      </Container>
      <BottomNav />
    </>
  );
};

export default Notifications;
