import axios from 'axios';
import {useContext, useEffect, useState} from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import {useNavigate} from 'react-router-dom';
import BottomNav from '../Navigation/BottomNav';
import TopNav from '../Navigation/TopNav';
import {LanguageContext} from '../contexts/LanguageProvider';
import {useFontFamilyByLanguage} from '../hooks/useFontFamilyByLanguage';
import {translate} from '../utils/translate';
import styled from 'styled-components';

// Styled Components
const ExplorerContainer = styled.div`
  font-family: ${props => props.fontFamily};
  text-align: center;
  color: #333;
  padding: 20px;
  max-width: 400px;
  margin: auto;
  background-color: #f1f6f8;
`;

const Header = styled.div`
  margin-bottom: 20px;
  font-family: ${props => props.fontFamily};
`;

const CategoryContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-bottom: 20px;
`;

const CategoryItem = styled.div`
  background-color: #ffffff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const ImageContainer = styled.div`
  width: 100px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
`;

const CategoryImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

const CategoryText = styled.span`
  font-size: 12px;
  color: #333;
`;

const FooterText = styled.p`
  margin-top: 20px;
  font-size: 14px;
  font-family: ${props => props.fontFamily};
`;

const Explorer = () => {
  const navigate = useNavigate();
  const {fontFamilies} = useFontFamilyByLanguage();
  const {language} = useContext(LanguageContext);
  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URI}/api/partner/list`,
        );
        const partnersData = response.data;

        // Find unique partner types
        const groupedCategories = partnersData.reduce((acc, partner) => {
          const type = partner.type;
          if (!acc[type]) acc[type] = [];
          acc[type].push(partner);
          return acc;
        }, {});

        setCategories(groupedCategories);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching partners:', error);
        navigate('/login');
      }
    };

    fetchCategories();
  }, [navigate]);

  return (
    <>
      <TopNav text={translate(language, 'explorer.title')} />
      <ExplorerContainer fontFamily={fontFamilies.regular}>
        <Header fontFamily={fontFamilies.semiBold}>
          <h2>{translate(language, 'explorer.headerTitle')}</h2>
          <p>{translate(language, 'explorer.headerDescription')}</p>
        </Header>

        {loading ? (
          <CategoryContainer>
            {Array(4)
              .fill(null)
              .map((_, index) => (
                <CategoryItem key={index}>
                  <ImageContainer>
                    <Skeleton circle height={50} width={50} />
                  </ImageContainer>
                  <Skeleton
                    height={20}
                    width={80}
                    style={{marginTop: '10px'}}
                  />
                </CategoryItem>
              ))}
          </CategoryContainer>
        ) : (
          Object.keys(categories).map(categoryType => (
            <div key={categoryType}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  margin: '20px 0',
                  color: '#CCC',
                }}>
                <h3 style={{marginRight: '10px'}}>
                  {categoryType.charAt(0).toUpperCase() + categoryType.slice(1)}
                </h3>
                <hr
                  style={{
                    flexGrow: 1,
                    border: 'none',
                    borderTop: '2px solid #CCC',
                  }}
                />
              </div>
              <CategoryContainer>
                {categories[categoryType].map(partner => (
                  <CategoryItem
                    key={partner._id}
                    onClick={() =>
                      navigate(`/partner/${partner._id}`, {
                        state: {showBackIcon: true},
                      })
                    }>
                    <ImageContainer>
                      <CategoryImage
                        src={partner.nameImg}
                        alt={partner.name[language]}
                      />
                    </ImageContainer>
                    <CategoryText>{partner.name[language]}</CategoryText>
                  </CategoryItem>
                ))}
              </CategoryContainer>
            </div>
          ))
        )}

        <FooterText fontFamily={fontFamilies.light}>
          {translate(language, 'explorer.footerText')}
        </FooterText>
      </ExplorerContainer>
      <BottomNav />
    </>
  );
};

export default Explorer;
