import {useLocation, useNavigate} from 'react-router-dom';
import BottomNav from '../Navigation/BottomNav';
import {useFontFamilyByLanguage} from '../hooks/useFontFamilyByLanguage';
import styled from 'styled-components';

// Styled Components
const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -100px;
  padding: 35px;
  font-family: ${props => props.fontFamily};
`;

const Message = styled.h1`
  font-size: 18px;
  color: #000;
  margin-bottom: 20px;
  font-family: ${props => props.fontFamily};
`;

const Button = styled.button`
  margin-top: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px;
  border-radius: 8px;
  border: 1px solid #d5d5d5;
  color: white;
  background-color: #3ddbd1;
  font-family: ${props => props.fontFamily};
  cursor: pointer;
`;

// Main Component
const DynamicMessagePage = () => {
  const {fontFamilies} = useFontFamilyByLanguage();
  const location = useLocation();
  const navigate = useNavigate();

  let message = location.state?.message;
  let buttonText = location.state?.buttonText;
  let buttonLink = location.state?.buttonLink;

  if (!message || !buttonText || !buttonLink) {
    const queryParams = new URLSearchParams(location.search);
    message = message || queryParams.get('message');
    buttonText = buttonText || queryParams.get('buttonText');
    buttonLink = buttonLink || queryParams.get('buttonLink');
  }

  return (
    <>
      <Container fontFamily={fontFamilies.regular}>
        <Message
          fontFamily={fontFamilies.regular}
          dangerouslySetInnerHTML={{__html: message.replace(/\n/g, '<br />')}}
        />
        <Button
          fontFamily={fontFamilies.medium}
          onClick={() => navigate(buttonLink)}>
          {buttonText || 'Go'}
        </Button>
      </Container>
      <BottomNav />
    </>
  );
};

export default DynamicMessagePage;
