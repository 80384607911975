import {useState, useContext} from 'react';
import styled from 'styled-components';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import {LanguageContext} from '../contexts/LanguageProvider';
import {translate} from '../utils/translate';
import {useFontFamilyByLanguage} from '../hooks/useFontFamilyByLanguage';
import BottomNav from '../Navigation/BottomNav';
import TopNav from '../Navigation/TopNav';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // Ensure you have this icon or replace with similar

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  font-family: ${props => props.fontFamily};
`;

const UserInput = styled.input`
  width: 100%;
  height: 40px;
  margin: 10px 0;
  padding: 0px 16px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 20px;
  background-color: #ffffff;
  font-family: ${props => props.fontFamily};
`;

const Button = styled.button`
  width: 100%;
  height: 40px;
  margin-top: 30px;
  padding: 0px;
  border: none;
  border-radius: 20px;
  background-color: #3ddbd1;
  color: #ffffff;
  font-family: ${props => props.fontFamily};
  cursor: pointer;
`;

const SuccessMessage = styled.div`
  font-size: 20px;
  color: green;
  margin-top: 20px;
`;

const Details = styled.div`
  font-size: 16px;
  color: #333;
`;

const Transfer = () => {
  const {language} = useContext(LanguageContext);
  const {fontFamilies} = useFontFamilyByLanguage();

  const [email, setEmail] = useState('');
  const [amount, setAmount] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [transactionDetails, setTransactionDetails] = useState(null);

  const handleTransfer = async () => {
    setLoading(true);
    setError('');

    const token = localStorage.getItem('token');
    setTransactionDetails(null); // Clear previous transaction details
    try {
      const response = await axios.post(
        '/api/transaction/transfer',
        {
          email,
          amount,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (response.status === 200) {
        setTransactionDetails(response.data); // Assuming response.data contains transaction details
        setLoading(false);
      } else {
        setError(translate(language, 'points.transferFail'));
        setLoading(false);
      }
    } catch (err) {
      console.error('Transfer Error:', err);
      setError(translate(language, 'points.transferFail'));
      setLoading(false);
    }
  };

  return (
    <>
      <TopNav text={translate(language, 'points.transfer')} />
      <Container fontFamily={fontFamilies.regular}>
        {error && <p style={{color: 'red'}}>{error}</p>}
        {!transactionDetails ? (
          <>
            <UserInput
              type="email"
              placeholder={translate(language, 'points.email')}
              value={email}
              onChange={e => setEmail(e.target.value)}
              fontFamily={fontFamilies.regular}
            />
            <UserInput
              type="number"
              placeholder={translate(language, 'points.amount')}
              value={amount}
              onChange={e => setAmount(e.target.value)}
              fontFamily={fontFamilies.regular}
            />
            <Button
              onClick={handleTransfer}
              fontFamily={fontFamilies.semiBold}
              disabled={!email || !amount || loading}>
              {loading
                ? translate(language, 'points.process')
                : translate(language, 'points.confirm')}
            </Button>
          </>
        ) : (
          <>
            <CheckCircleIcon style={{fontSize: 60, color: '#3BB143'}} />
            <SuccessMessage>
              {translate(language, 'points.transactionSuccess')}
            </SuccessMessage>
            <Details>
              <p>Email: {transactionDetails.email}</p>
              <p>Amount: {transactionDetails.amount}</p>
              <p>Transaction ID: {transactionDetails.transactionId}</p>
              <br />
              <p>The transfer has been successfully processed</p>
              {/* Adjust fields as per your API response */}
            </Details>
          </>
        )}
      </Container>
      <BottomNav />
    </>
  );
};

export default Transfer;
