import axios from 'axios';

export const isAuthenticated = async () => {
  try {
    // Send a GET request to check the authentication status with credentials (cookies)
    const response = await axios.get(`/api/auth/checkAuthToken`);

    // Assuming the API returns 200 for successful authentication
    return response.status === 200;
  } catch (error) {
    console.error('Error during auth token validation:', error);
    return false;
  }
};
