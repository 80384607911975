import axios from 'axios';
import {useContext, useState} from 'react';
import styled from 'styled-components';
import BottomNav from '../Navigation/BottomNav';
import TopNav from '../Navigation/TopNav';
import {LanguageContext} from '../contexts/LanguageProvider';
import {useFontFamilyByLanguage} from '../hooks/useFontFamilyByLanguage';
import {translate} from '../utils/translate';

// Styled components
const Container = styled.div`
  margin: 30px;
  font-family: ${props => props.fontFamily};
  height: 80vh;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LanguageSwitch = styled.div`
  display: flex;
  align-items: center;
`;

const LanguageButton = styled.button`
  cursor: pointer;
  padding: 5px 10px;
  background-color: ${props => (props.active ? '#3DDBD1' : '#f0f0f0')};
  border: none;
  border-radius: 5px;
  color: ${props => (props.active ? 'white' : '#3DDBD1')};
  font-family: ${props => props.fontFamily};
  margin-left: 5px;
`;

const Item = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const CheckIcon = styled.span`
  margin-left: 10px;
  color: green;
  font-size: 20px;
`;

const Settings = () => {
  const {fontFamilies} = useFontFamilyByLanguage();
  const {language, switchLanguage} = useContext(LanguageContext);
  const [biometricEnabled, setBiometricEnabled] = useState(
    localStorage.getItem('credentialId') ? true : false,
  );
  const [userInfo] = useState(
    JSON.parse(localStorage.getItem('userInfo')) || {},
  );

  const handleLanguageSwitch = lang => {
    switchLanguage(lang);
    localStorage.setItem('Language', lang);
  };

  const handleEnableBiometricLogin = async () => {
    if (!window.PublicKeyCredential) {
      alert('Biometric login is not supported by this browser.');
      return;
    }

    try {
      const challenge = new Uint8Array(32);
      window.crypto.getRandomValues(challenge);

      const publicKey = {
        challenge: challenge,
        rp: {
          name: 'CarbonMetA Carbon Point',
        },
        user: {
          id: new Uint8Array(
            [...Array(16)].map(() => Math.floor(Math.random() * 256)),
          ),
          name: userInfo.firstName + ' ' + userInfo.lastName,
          displayName: userInfo.firstName,
          email: userInfo.email,
        },
        pubKeyCredParams: [{type: 'public-key', alg: -7}], // ES256
        timeout: 60000,
        authenticatorSelection: {
          authenticatorAttachment: 'platform',
          userVerification: 'required',
        },
        attestation: 'direct',
      };

      const credential = await navigator.credentials.create({publicKey});
      const credentialResponse = {
        id: credential.id,
        rawId: btoa(String.fromCharCode(...new Uint8Array(credential.rawId))),
        type: credential.type,
        response: {
          clientDataJSON: btoa(
            String.fromCharCode(
              ...new Uint8Array(credential.response.clientDataJSON),
            ),
          ),
          attestationObject: btoa(
            String.fromCharCode(
              ...new Uint8Array(credential.response.attestationObject),
            ),
          ),
        },
      };

      const token = localStorage.getItem('token');
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };

      await axios.post(
        `${process.env.REACT_APP_API_URI}/api/auth/registerBiometric`,
        credentialResponse,
        config,
      );
      localStorage.setItem(
        'credentialId',
        btoa(String.fromCharCode(...new Uint8Array(credential.rawId))),
      );
      setBiometricEnabled(true);
      alert('Biometric login enabled successfully.');
    } catch (err) {
      console.error('Failed to create a credential:', err);
      alert('Error enabling biometric login: ' + err.message);
    }
  };

  return (
    <>
      <TopNav text={translate(language, 'settings.language')} />
      <Container fontFamily={fontFamilies.regular}>
        <Header>
          <div>{translate(language, 'settings.language')}</div>
          <LanguageSwitch>
            <LanguageButton
              fontFamily={fontFamilies.regular}
              active={language === 'en'}
              onClick={() => handleLanguageSwitch('en')}>
              EN
            </LanguageButton>
            <LanguageButton
              fontFamily={fontFamilies.regular}
              active={language === 'zh'}
              onClick={() => handleLanguageSwitch('zh')}>
              ZH
            </LanguageButton>
            <LanguageButton
              fontFamily={fontFamilies.regular}
              active={language === 'zhhk'}
              onClick={() => handleLanguageSwitch('zhhk')}>
              ZHHK
            </LanguageButton>
          </LanguageSwitch>
        </Header>
        <Item onClick={handleEnableBiometricLogin}>
          {translate(language, 'settings.enableBiometricLogin')}{' '}
          {biometricEnabled && <CheckIcon>&#x2714;</CheckIcon>}
        </Item>
      </Container>
      <BottomNav />
    </>
  );
};

export default Settings;
