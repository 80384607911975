import styled from 'styled-components';
import InfoIcon from '@mui/icons-material/Info';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import {useContext, useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {LanguageContext} from '../contexts/LanguageProvider';
import {useFontFamilyByLanguage} from '../hooks/useFontFamilyByLanguage';
import {translate} from '../utils/translate';

// Styled components
const TopNavContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: 0;
  background-color: #3ddbd1;
  box-shadow: 0px 1px 12px rgba(193, 193, 193, 0.25);
  height: 80px;
  min-width: 300px;
  width: 100%;
  z-index: 10;
  max-width: 680px;
  margin: 0 auto;

  @media (min-width: 680px) {
    max-width: 680px;
    margin: 0 auto;
  }
`;

const BackIconContainer = styled.div`
  position: absolute;
  left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white;
  cursor: pointer;
`;

const BackIcon = styled.svg`
  color: #030303;
  width: 20px;
  height: 20px;
`;

const NavText = styled.div`
  font-family: ${props => props.fontFamily};
  color: white;
  font-size: 20px;
  text-align: center;
  flex-grow: 1;
`;

const InfoIconContainer = styled.div`
  position: absolute;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Placeholder = styled.div`
  height: 60px;
  padding-bottom: 20px;
`;

const InfoButtonIcon = styled(InfoIcon)`
  color: white;
  width: 30px;
  height: 30px;
`;

// Main Component
const TopNav = ({text = 'Title', info}) => {
  const {language} = useContext(LanguageContext);
  const [isMobile, setIsMobile] = useState(
    window.innerWidth / window.innerHeight < 1,
  );
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth / window.innerHeight < 1);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const {fontFamilies} = useFontFamilyByLanguage();

  const BackIconComponent = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const showBackIcon = location.state?.showBackIcon;

    if (!showBackIcon) return null;

    return (
      <BackIconContainer onClick={() => navigate(-1)}>
        <BackIcon viewBox="0 0 256 512">
          <path d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z"></path>
        </BackIcon>
      </BackIconContainer>
    );
  };

  const handleInfoClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const InfoButton = () => {
    if (!info) return null;

    return (
      <InfoIconContainer onClick={handleInfoClick}>
        <InfoButtonIcon />
      </InfoIconContainer>
    );
  };

  return (
    <>
      <Placeholder />
      <TopNavContainer>
        <BackIconComponent />
        <NavText fontFamily={fontFamilies.semiBold}>{text}</NavText>
        <InfoButton />
      </TopNavContainer>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{translate(language, 'infoDialog.title')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{info}</DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TopNav;
