import axios from 'axios';
import {useEffect, useState} from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';

// Styled Components
const ArticlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const SkeletonArticlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  min-width: 80%;
`;

const ArticleCard = styled.div`
  width: 100%;
  margin-bottom: 20px;
  cursor: pointer;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: left;
`;

const ArticleImage = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
`;

const ArticleTitle = styled.h3`
  font-size: 18px;
  margin-top: 10px;
  color: #333;
`;

const MyLikes = ({language}) => {
  const [likedArticles, setLikedArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLikedArticles = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URI}/api/article/liked`,
        );
        setLikedArticles(response.data);
      } catch (err) {
        console.error('Error fetching liked articles:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchLikedArticles();
  }, [navigate]);

  const handleCardClick = articleId => {
    navigate(`/article/${articleId}`, {state: {showBackIcon: true}});
  };

  return (
    <>
      {loading ? (
        <SkeletonArticlesContainer>
          <ArticleCard>
            <Skeleton height={150} />
            <Skeleton height={20} width="80%" style={{marginTop: '10px'}} />
          </ArticleCard>
          <ArticleCard>
            <Skeleton height={150} />
            <Skeleton height={20} width="80%" style={{marginTop: '10px'}} />
          </ArticleCard>
          <ArticleCard>
            <Skeleton height={150} />
            <Skeleton height={20} width="80%" style={{marginTop: '10px'}} />
          </ArticleCard>
        </SkeletonArticlesContainer>
      ) : (
        <ArticlesContainer>
          {likedArticles.map(article => (
            <ArticleCard
              key={article._id}
              onClick={() => handleCardClick(article._id)}>
              <ArticleImage
                src={article.coverImg[language]}
                alt={article.title[language]}
              />
              <ArticleTitle>{article.title[language]}</ArticleTitle>
            </ArticleCard>
          ))}
        </ArticlesContainer>
      )}
    </>
  );
};

export default MyLikes;
