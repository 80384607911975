import {useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import VisibilityIcon from '@mui/icons-material/Visibility';
import BottomNav from '../Navigation/BottomNav';
import TopNav from '../Navigation/TopNav';
import {LanguageContext} from '../contexts/LanguageProvider';
import {translate} from '../utils/translate';
import styled from 'styled-components';

// Styled Components
const ArticleContainer = styled.div`
  padding: 20px;
  text-align: center;
`;

const ArticleImage = styled.img`
  width: 100%;
  border-radius: 10px;
`;

const InteractionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
`;

const InteractionItem = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  color: #3ddbd1;
  cursor: pointer;
`;

const ArticleContent = styled.div`
  text-align: left;
  margin-top: 20px;
`;

const DatePosted = styled.small`
  display: block;
  margin-top: 10px;
`;

// Main Component
const ArticlePage = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(
    Cookies.get('userInfo') ? JSON.parse(Cookies.get('userInfo')) : {},
  );
  const {language} = useContext(LanguageContext);
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [liked, setLiked] = useState(false);

  useEffect(() => {
    const fetchArticleDetail = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URI}/api/article/detail/${id}`,
        );
        setArticle(response.data);

        const userId = userInfo._id;
        setLiked(response.data.likedUsers.includes(userId));
      } catch (err) {
        console.error('Error fetching article details:', err);
        setError(translate(language, 'articlePage.error'));
      } finally {
        setLoading(false);
      }
    };

    fetchArticleDetail();
  }, [id, navigate, language]);

  const handleLike = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URI}/api/article/like/${id}`,
      );

      const userId = userInfo._id;
      setLiked(true);
      setArticle(prev => ({
        ...prev,
        likedUsers: [...prev.likedUsers, userId],
      }));
    } catch (err) {
      console.error('Error liking article:', err);
    }
  };

  const handleUnlike = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URI}/api/article/unlike/${id}`,
      );

      const userId = userInfo._id;
      setLiked(false);
      setArticle(prev => ({
        ...prev,
        likedUsers: prev.likedUsers.filter(uid => uid !== userId),
      }));
    } catch (err) {
      console.error('Error unliking article:', err);
    }
  };

  const likeIcon = liked ? (
    <FavoriteIcon
      sx={{color: '#3DDBD1', cursor: 'pointer'}}
      onClick={handleUnlike}
    />
  ) : (
    <FavoriteBorderIcon
      sx={{color: '#3DDBD1', cursor: 'pointer'}}
      onClick={handleLike}
    />
  );

  if (loading) {
    return (
      <div>
        <TopNav text={translate(language, 'articlePage.title')} />
        <ArticleContainer>
          <h1>
            <Skeleton width={200} />
          </h1>
          <Skeleton height={300} />
          <InteractionContainer>
            <Skeleton width={100} height={40} />
            <Skeleton width={100} height={40} />
          </InteractionContainer>
          <ArticleContent>
            <Skeleton count={5} />
          </ArticleContent>
          <DatePosted>
            <Skeleton width={150} />
          </DatePosted>
        </ArticleContainer>
        <BottomNav />
      </div>
    );
  }

  if (error) return <p>{translate(language, 'articlePage.error')}</p>;

  return (
    <div>
      <TopNav text={translate(language, 'articlePage.title')} />
      {article && (
        <ArticleContainer>
          <h1>{article.title[language]}</h1>
          <ArticleImage src={article.coverImg[language]} alt="Cover" />
          <InteractionContainer>
            <InteractionItem>
              <VisibilityIcon sx={{color: '#3DDBD1'}} />{' '}
              {article.readUsers.length}
            </InteractionItem>
            <InteractionItem>
              {likeIcon} {article.likedUsers.length}
            </InteractionItem>
          </InteractionContainer>
          <ArticleContent>
            <div
              dangerouslySetInnerHTML={{__html: article.content[language]}}
            />
          </ArticleContent>
          <DatePosted>
            {translate(language, 'articlePage.postedOn')}:{' '}
            {new Date(article.created).toLocaleDateString()}
          </DatePosted>
        </ArticleContainer>
      )}
      <BottomNav />
    </div>
  );
};

export default ArticlePage;
