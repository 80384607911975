import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import {useContext, useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import ErrorBoundary from '../components/ErrorBoundary';
import {LanguageContext} from '../contexts/LanguageProvider';
import {translate} from '../utils/translate';
import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components';
import QrScanner from 'qr-scanner';

const Container = styled.div``;

const ScannerContainer = styled.div``;

const CameraPreview = styled.div``;

const Overlay = styled.div`
  position: absolute;
  bottom: 100px; /* Stick to the bottom */
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Align content to the bottom */
  align-items: center;
  z-index: 1;
  pointer-events: none;
`;

const Frame = styled.div`
  width: 80%;
  height: 50%;
  border: 5px solid white;
  border-radius: 12px;
  z-index: 1;
`;

const InstructionText = styled.div`
  color: white;
  margin-top: 20px;
  font-size: 1rem;
  text-align: center;
`;

const ErrorText = styled.div`
  color: red;
  font-size: 1rem;
  margin-top: 10px;
`;

const SpinnerOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
`;

const CloseButton = styled(CloseIcon)`
  color: white;
  font-size: 4rem;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 3;
  cursor: pointer;
`;

function QRScanPage() {
  const navigate = useNavigate();
  const {language} = useContext(LanguageContext);
  const [scanResult, setScanResult] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const videoElementRef = useRef();

  useEffect(() => {
    let qrScanner;

    if (videoElementRef.current) {
      qrScanner = new QrScanner(
        videoElementRef.current,
        result => {
          if (result && !isProcessing) {
            setScanResult(result.data);
          }
        },
        {
          highlightScanRegion: true,
          facingMode: 'environment',
        },
      );

      qrScanner.start().catch(error => {
        console.error('QR Scanner Error:', error);
        setErrorMessage(translate(language, 'qrScan.focusOnQrCode'));
      });
    }

    return () => {
      if (qrScanner) {
        qrScanner.stop();
      }
    };
  }, [isProcessing, language]);

  useEffect(() => {
    const processScanResult = async () => {
      if (!scanResult || isProcessing) return;

      setIsProcessing(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URI}/api/redeem/scanQR`,
          {qr: {text: scanResult}},
        );

        navigate('/message', {
          state: {
            message:
              translate(language, 'qrScan.success') + response?.data?.message,
            buttonText: translate(language, 'qrScan.returnHome'),
            buttonLink: '/',
          },
        });
      } catch (error) {
        console.error('Error during QR Scan:', error);
        navigate('/message', {
          state: {
            message: translate(language, 'qrScan.invalidQrCode'),
            buttonText: translate(language, 'qrScan.returnHome'),
            buttonLink: '/',
          },
        });
      } finally {
        setIsProcessing(false);
      }
    };

    processScanResult();
  }, [scanResult, isProcessing, navigate, language]);

  const handleClose = () => {
    if (videoElementRef.current) {
      const tracks = videoElementRef.current.srcObject?.getTracks();
      if (tracks) {
        tracks.forEach(track => track.stop());
      }
    }
    navigate('/');
  };

  return (
    <Container>
      {isProcessing && (
        <SpinnerOverlay>
          <CircularProgress color="inherit" size={80} />
        </SpinnerOverlay>
      )}
      <ScannerContainer>
        <CameraPreview>
          <video
            ref={videoElementRef}
            style={{
              width: '100vw',
              height: '100vh',
              zIndex: '1',
              objectFit: 'cover',
            }}
          />
        </CameraPreview>
        <Overlay>
          <InstructionText>
            {translate(language, 'qrScan.aimAtQrCode')}
          </InstructionText>
          <InstructionText>
            {translate(language, 'qrScan.fitQrCodeInBox')}
          </InstructionText>
          <InstructionText>
            {translate(language, 'qrScan.additionalMessage')}
          </InstructionText>
          {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
        </Overlay>
        <CloseButton onClick={handleClose} />
      </ScannerContainer>
    </Container>
  );
}

export default function WrappedQRScanPage() {
  return (
    <ErrorBoundary>
      <QRScanPage />
    </ErrorBoundary>
  );
}
