import Skeleton from '@mui/material/Skeleton';
import axios from 'axios';
import Cookies from 'js-cookie';
import {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {ReactSVG} from 'react-svg';
import BottomNav from '../Navigation/BottomNav';
import TopNav from '../Navigation/TopNav';
import {LanguageContext} from '../contexts/LanguageProvider';
import {useFontFamilyByLanguage} from '../hooks/useFontFamilyByLanguage';
import {translate} from '../utils/translate';
import styled from 'styled-components';

// Styled Components
const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  background-color: #3ddbd1;
  margin-top: -20px;
  padding-top: 20px;
  border-radius: 20px;
  z-index: 1000;
`;

const TopContainerItem = styled.div`
  padding: 20px;
  width: 40%;
  margin-bottom: -20px;
  background-color: #ffffff;
  border-radius: 20px;
  font-size: 12px;
  font-family: ${props => props.fontFamily};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const PointsLabel = styled.div`
  font-size: 12px;
  font-family: ${props => props.fontFamily};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PointsDisplay = styled.div`
  font-size: 18px;
  font-family: ${props => props.fontFamily};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CoinIcon = styled(ReactSVG)`
  height: 25px;
  width: 25px;
  margin-right: 5px;
`;

const TransferButton = styled.button`
  margin: 100px auto 0 auto; /* This centers the button horizontally */
  display: block;
  width: 60%;
  padding: 10px 20px;
  background-color: #3ddbd1;
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  font-family: ${props => props.fontFamily};
  cursor: pointer;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #33c4b9;
  }
`;

const Container = styled.div`
  margin-top: 20px;
`;

const ItemContainer = styled.div`
  margin: 20px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ItemRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ItemTitle = styled.div`
  font-size: 12px;
  font-family: ${props => props.fontFamily};
`;

const ItemPoints = styled.div`
  font-size: 12px;
  font-family: ${props => props.fontFamily};
`;

const ItemDatetime = styled.div`
  font-size: 12px;
  font-family: ${props => props.fontFamily};
`;

const ItemDescription = styled.div`
  font-size: 12px;
  font-family: ${props => props.fontFamily};
`;

const Points = () => {
  const navigate = useNavigate();
  const {language} = useContext(LanguageContext);
  const {fontFamilies} = useFontFamilyByLanguage();

  const [userInfo, setUserInfo] = useState(
    Cookies.get('userInfo') ? JSON.parse(Cookies.get('userInfo')) : {},
  );
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  // Poll for changes in the cookie every second
  useEffect(() => {
    const interval = setInterval(() => {
      const updatedUserInfo = Cookies.get('userInfo')
        ? JSON.parse(Cookies.get('userInfo'))
        : {};
      if (JSON.stringify(updatedUserInfo) !== JSON.stringify(userInfo)) {
        setUserInfo(updatedUserInfo);
      }
    }, 1000); // Poll every second

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, [userInfo]);

  useEffect(() => {
    const fetchTransactions = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`/api/transaction/listMyTransactions`);
        if (response.data && Array.isArray(response.data)) {
          const processedTransactions = response.data
            .filter(
              transaction => transaction && typeof transaction === 'object',
            ) // Ensure each transaction is an object
            .map(transaction => {
              if (transaction.type === 'Transfer - Debit') {
                return {
                  ...transaction,
                  itemSnapshot: {
                    name: {
                      [language]: translate(language, 'points.transferDebit'),
                    },
                    description: {
                      [language]: translate(
                        language,
                        'points.transferDebitDescription',
                      ),
                    },
                  },
                };
              } else if (transaction.type === 'Transfer - Credit') {
                return {
                  ...transaction,
                  itemSnapshot: {
                    name: {
                      [language]: translate(language, 'points.transferCredit'),
                    },
                    description: {
                      [language]: translate(
                        language,
                        'points.transferCreditDescription',
                      ),
                    },
                  },
                };
              }
              return transaction;
            })
            .filter(
              transaction =>
                transaction.type?.startsWith('Transfer - ') ||
                (transaction.itemSnapshot?.name?.[language] &&
                  transaction.itemSnapshot?.description?.[language]),
            );

          setTransactions(processedTransactions);

          console.log(transactions);
        }
      } catch (error) {
        console.error('Failed to fetch transactions:', error);
        setError(translate(language, 'points.fetchError'));
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, [navigate, language]);

  return (
    <>
      <TopNav text={translate(language, 'points.myPoints')} />
      <TopContainer>
        <TopContainerItem fontFamily={fontFamilies.light}>
          {loading ? (
            <>
              <Skeleton variant="text" width={100} />
              <Skeleton variant="text" width={50} />
            </>
          ) : (
            <>
              <PointsLabel fontFamily={fontFamilies.regular}>
                {translate(language, 'points.pointsBalance')}
              </PointsLabel>
              <PointsDisplay fontFamily={fontFamilies.semiBold}>
                <CoinIcon src="/assets/icons/coins.svg" />
                {userInfo.points}
              </PointsDisplay>
            </>
          )}
        </TopContainerItem>
      </TopContainer>

      {/* Transfer Button */}
      <TransferButton
        fontFamily={fontFamilies.semiBold}
        onClick={() =>
          navigate(`/transfer`, {
            state: {showBackIcon: true},
          })
        }>
        {translate(language, 'points.transfer')}
      </TransferButton>

      <Container>
        {loading ? (
          <>
            <Skeleton
              variant="rectangular"
              height={80}
              style={{marginBottom: '20px'}}
            />
            <Skeleton
              variant="rectangular"
              height={80}
              style={{marginBottom: '20px'}}
            />
            <Skeleton
              variant="rectangular"
              height={80}
              style={{marginBottom: '20px'}}
            />
          </>
        ) : error ? (
          <p>{error}</p>
        ) : transactions.length > 0 ? (
          transactions.map(transaction => (
            <ItemContainer key={transaction.id}>
              <ItemRow>
                <ItemTitle fontFamily={fontFamilies.semiBold}>
                  {transaction.itemSnapshot.name[language]}
                </ItemTitle>
                <ItemPoints fontFamily={fontFamilies.semiBold}>
                  {transaction.points} {translate(language, 'points.points')}
                </ItemPoints>
              </ItemRow>
              <ItemDatetime fontFamily={fontFamilies.light}>
                {new Date(transaction.created).toLocaleString()}
              </ItemDatetime>
              <ItemDescription fontFamily={fontFamilies.regular}>
                {transaction.itemSnapshot.description[language]}
              </ItemDescription>
            </ItemContainer>
          ))
        ) : (
          <p>{translate(language, 'points.noTransactions')}</p>
        )}
      </Container>
      <BottomNav />
    </>
  );
};

export default Points;
