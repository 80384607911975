import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import {useContext, useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {LanguageContext} from '../contexts/LanguageProvider';
import {useFontFamilyByLanguage} from '../hooks/useFontFamilyByLanguage';
import {translate} from '../utils/translate';
import styled from 'styled-components';

// Styled Components
const ModalBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
  background-color: white;
  box-shadow: 24px;
  border-radius: 10px;
  padding: 16px;
  font-family: ${props => props.fontFamilyBold};
`;

const DisclaimerText = styled.p`
  font-size: 10px;
  font-family: ${props => props.fontFamilyLightItalic};
`;

const CloseButton = styled(CloseIcon)`
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  font-size: 2rem;
`;

const CenteredBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const RedeemItemDetail = ({isOpen, selectedVoucher, handleClose}) => {
  const navigate = useNavigate();
  const {fontFamilies} = useFontFamilyByLanguage();
  const {language} = useContext(LanguageContext);

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmScroll, setConfirmScroll] = useState('paper');
  const [walletAddress, setWalletAddress] = useState('');
  const [loading, setLoading] = useState(false);
  const [quantity] = useState(1);
  const token = localStorage.getItem('token');

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (confirmOpen) {
      const {current: descriptionElement} = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [confirmOpen]);

  const handleConfirmOpen = scrollType => () => {
    setConfirmOpen(true);
    setConfirmScroll(scrollType);
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: 'eth_requestAccounts',
        });
        setWalletAddress(addressArray[0]);
        console.log('Connected:', addressArray[0]);
      } catch (err) {
        console.error('Error connecting to MetaMask:', err);
        alert(translate(language, 'redeemItemDetail.errorConnectingMetaMask'));
      }
    } else if (window.web3) {
      const addressArray = await window.web3.eth.getAccounts();
      setWalletAddress(addressArray[0]);
      console.log('Connected:', addressArray[0]);
    } else {
      alert(translate(language, 'redeemItemDetail.installMetaMask'));
    }
  };

  const handleRedeem = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URI}/api/redeem/purchase/${selectedVoucher._id}`,
        {quantity, walletAddress},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (response.status === 200) {
        const message = `${translate(language, 'redeemItemDetail.itemRedeemedSuccessfully')}:\n${selectedVoucher?.name[language]}`;
        navigate('/message', {
          state: {
            message,
            buttonText: translate(language, 'redeemItemDetail.returnToRedeem'),
            buttonLink: '/redeem',
          },
        });
      } else {
        navigate('/message', {
          state: {
            message: translate(language, 'redeemItemDetail.notEnoughPoints'),
            buttonText: translate(language, 'redeemItemDetail.returnToRedeem'),
            buttonLink: '/redeem',
          },
        });
      }
    } catch (error) {
      navigate('/message', {
        state: {
          message: translate(language, 'redeemItemDetail.redeemUnavailable'),
          buttonText: translate(language, 'redeemItemDetail.returnToRedeem'),
          buttonLink: '/redeem',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const disclaimerText = translate(language, 'redeemItemDetail.disclaimer');

  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description">
        <ModalBox fontFamilyBold={fontFamilies.bold}>
          <CloseButton onClick={handleClose} />
          <h2>{selectedVoucher?.name?.[language] || ''}</h2>
          <p>
            {translate(language, 'redeemItemDetail.description')}:{' '}
            {selectedVoucher?.description?.[language] || ''}
          </p>
          <p>
            {translate(language, 'redeemItemDetail.quotaLimits')}:{' '}
            {selectedVoucher?.quotaLimits || 'No Limits'}
          </p>
          <p>
            {translate(language, 'redeemItemDetail.availableToRedeem')}:{' '}
            {selectedVoucher?.availableToRedeem || 'No Limits'}
          </p>

          {selectedVoucher?.type !== 'exchange' ? (
            <Button
              variant="contained"
              onClick={handleConfirmOpen('paper')}
              style={{marginTop: 16}}>
              {translate(language, 'redeemItemDetail.redeem')}
            </Button>
          ) : walletAddress ? (
            <>
              <Typography
                variant="body1"
                style={{marginTop: 16, wordWrap: 'break-word'}}>
                {translate(language, 'redeemItemDetail.walletConnected')}:{' '}
                {walletAddress}
              </Typography>
              <Button
                variant="contained"
                onClick={handleConfirmOpen('paper')}
                style={{marginTop: 16}}>
                {translate(language, 'redeemItemDetail.redeem')}
              </Button>
            </>
          ) : (
            <Button
              variant="contained"
              onClick={connectWallet}
              style={{marginTop: 16}}>
              {translate(language, 'redeemItemDetail.connectWallet')}
            </Button>
          )}
        </ModalBox>
      </Modal>

      <Dialog
        open={confirmOpen}
        onClose={handleConfirmClose}
        scroll={confirmScroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description">
        <DialogTitle id="scroll-dialog-title">
          {translate(language, 'redeemItemDetail.redeem')}:{' '}
          {selectedVoucher?.name?.[language] || ''}
          <br />
          <br />
          {translate(language, 'redeemItemDetail.confirmMessage')}
        </DialogTitle>
        <DialogContent dividers={confirmScroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}>
            <DisclaimerText fontFamilyLightItalic={fontFamilies.lightItalic}>
              {disclaimerText}
            </DisclaimerText>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmClose}>
            {translate(language, 'redeemItemDetail.cancel')}
          </Button>
          <Button onClick={handleRedeem}>
            {translate(language, 'redeemItemDetail.confirm')}
          </Button>
        </DialogActions>
      </Dialog>

      {loading && (
        <CenteredBox>
          <CircularProgress />
        </CenteredBox>
      )}
    </>
  );
};

export default RedeemItemDetail;
