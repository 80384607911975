import axios from 'axios';
import Cookies from 'js-cookie';
import {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import BottomNav from '../Navigation/BottomNav';
import TopNav from '../Navigation/TopNav';
import {LanguageContext} from '../contexts/LanguageProvider';
import {useFontFamilyByLanguage} from '../hooks/useFontFamilyByLanguage';
import {translate} from '../utils/translate';
import RedeemItemDetail from './RedeemItemDetail';
import styled from 'styled-components';

// Styled Components
const RedeemContainer = styled.div`
  font-family: ${props => props.fontFamily};
  text-align: center;
  color: #333;
  padding: 20px;
  max-width: 400px;
  margin: auto;
  background-color: #f1f6f8;
`;

const PointsBalanceSection = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #fff;
  margin: 10px 0;
  border-radius: 10px;
  font-family: ${props => props.fontFamily};
`;

const VoucherList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin: 20px 0;
`;

const VoucherItem = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
`;

const VoucherImage = styled.img`
  max-width: 100%;
  height: 77px;
  margin-bottom: 10px;
`;

const VoucherName = styled.span`
  font-family: ${props => props.fontFamily};
  margin-bottom: 5px;
`;

const VoucherDescription = styled.span`
  font-family: ${props => props.fontFamily};
  margin-top: 5px;
`;

const VoucherPoints = styled.span`
  font-family: ${props => props.fontFamily};
`;

const InstructionSection = styled.div`
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  margin: 10px 0;
  font-family: ${props => props.fontFamily};
  text-align: left;
`;

const GCXLink = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  margin: 10px 0;
  border-radius: 10px;
  font-family: ${props => props.fontFamily};
`;

const RedeemPage = () => {
  const navigate = useNavigate();
  const {language} = useContext(LanguageContext);
  const {fontFamilies} = useFontFamilyByLanguage();

  const [userInfo, setUserInfo] = useState(Cookies.get('userInfo') || {});
  const [isOpen, setIsOpen] = useState(false);
  const [selectedVoucher, setSelectedVoucher] = useState({});
  const [vouchers, setVouchers] = useState([]);
  const [, setLoading] = useState(false);
  const [, setError] = useState(null);

  useEffect(() => {
    try {
      setUserInfo(JSON.parse(Cookies.get('userInfo')));
    } catch {
      null;
    }
    const fetchUserInfo = async () => {
      try {
        await axios.get(
          `${process.env.REACT_APP_API_URI}/api/auth/getUserInfo`,
        );
        setUserInfo(JSON.parse(Cookies.get('userInfo')));
      } catch (error) {
        console.error('Failed to fetch user info:', error);
      }
    };

    fetchUserInfo();
  }, []);

  useEffect(() => {
    const fetchVouchers = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`/api/redeem/list`);
        setVouchers(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching vouchers:', err);
        setError(err.message);
        setLoading(false);
      }
    };

    fetchVouchers();
  }, [navigate]);

  const handleOpen = voucher => {
    setSelectedVoucher(voucher);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const exchangeVouchers = vouchers.filter(
    voucher => voucher.type === 'exchange',
  );
  const otherVouchers = vouchers.filter(voucher => voucher.type === 'coupon');

  return (
    <>
      <TopNav
        text={translate(language, 'redeemPage.title')}
        info={translate(language, 'infoDialog.redeemDescription')}
      />
      <RedeemContainer fontFamily={fontFamilies.regular}>
        <PointsBalanceSection fontFamily={fontFamilies.medium}>
          <span>{translate(language, 'redeemPage.pointsAvailable')}</span>
          <span>{userInfo.points}</span>
        </PointsBalanceSection>
        <GCXLink fontFamily={fontFamilies.medium}>
          <a
            href="https://www.main.gctc.io/"
            target="_blank"
            rel="noopener noreferrer">
            Click for GCX Page Info
          </a>
        </GCXLink>
        <VoucherList>
          {exchangeVouchers.map((voucher, index) => (
            <VoucherItem key={index} onClick={() => handleOpen(voucher)}>
              <VoucherImage src={voucher.image} alt={voucher.name[language]} />
              <VoucherName fontFamily={fontFamilies.bold}>
                {voucher.name[language]}
              </VoucherName>
              <VoucherDescription fontFamily={fontFamilies.regular}>
                {voucher.description[language]}
              </VoucherDescription>
              <VoucherPoints fontFamily={fontFamilies.light}>
                {voucher.points} pts
              </VoucherPoints>
            </VoucherItem>
          ))}
        </VoucherList>
        <VoucherList>
          {otherVouchers.map((voucher, index) => (
            <VoucherItem key={index} onClick={() => handleOpen(voucher)}>
              <VoucherImage src={voucher.image} alt={voucher.name[language]} />
              <VoucherName fontFamily={fontFamilies.bold}>
                {voucher.name[language]}
              </VoucherName>
              <VoucherDescription fontFamily={fontFamilies.regular}>
                {voucher.description[language]}
              </VoucherDescription>
              <VoucherPoints fontFamily={fontFamilies.light}>
                {voucher.points} pts
              </VoucherPoints>
            </VoucherItem>
          ))}
        </VoucherList>
        <InstructionSection fontFamily={fontFamilies.medium}>
          <p>{translate(language, 'redeemPage.redeemNow')}</p>
          <p>{translate(language, 'redeemPage.redeemInstructions')}</p>
        </InstructionSection>
      </RedeemContainer>
      <RedeemItemDetail
        isOpen={isOpen}
        selectedVoucher={selectedVoucher}
        handleClose={handleClose}
      />
      <BottomNav />
    </>
  );
};

export default RedeemPage;
