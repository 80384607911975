import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Skeleton from 'react-loading-skeleton';
import axios from 'axios';
import {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import BottomNav from '../Navigation/BottomNav';
import TopNav from '../Navigation/TopNav';
import {LanguageContext} from '../contexts/LanguageProvider';
import {useFontFamilyByLanguage} from '../hooks/useFontFamilyByLanguage';
import {translate} from '../utils/translate';
import QRCode from 'qrcode.react';
import styled from 'styled-components';

// Styled Components
const Container = styled.div`
  font-family: ${props => props.fontFamily};
  text-align: center;
  color: #333;
  padding: 20px;
  max-width: 400px;
  margin: auto;
  background-color: #f1f6f8;
`;

const RewardsList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-top: 20px;
`;

const RewardItem = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;

const RewardImage = styled.img`
  max-width: 100%;
  height: 77px;
  margin-bottom: 10px;
`;

const RewardText = styled.span`
  font-size: 12px;
  color: #333;
`;

const ActionButton = styled.button`
  font-family: ${props => props.fontFamily};
  background-color: #3ddbd1;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
`;

const Rewards = ({type}) => {
  const navigate = useNavigate();
  const {fontFamilies} = useFontFamilyByLanguage();
  const {language} = useContext(LanguageContext);
  const [rewards, setRewards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [itemContent, setItemContent] = useState(null);
  const [qrCode, setQrCode] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    const fetchRewards = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URI}/api/redeem/myredeems?type=${type}`,
        );

        const redeemItemResponse = await axios.get(
          `${process.env.REACT_APP_API_URI}/api/redeem/list`,
        );
        console.log('response.data', response.data);

        const myRewards = response.data.map(reward => {
          const redeemItem = redeemItemResponse.data.find(
            item => item._id === reward.redeemItemSnapshot._id,
          );
          const replaceItem = redeemItem
            ? redeemItem
            : reward.redeemItemSnapshot;
          return {
            ...reward,
            redeemItemSnapshot: {
              ...replaceItem,
            },
          };
        });

        setRewards(myRewards);
        console.log(rewards);
      } catch (error) {
        console.error('Failed to fetch rewards:', error);
        setError(translate(language, 'rewards.error'));
      } finally {
        setLoading(false);
      }
    };

    fetchRewards();
  }, [language, type]);

  const handleCardClick = item => {
    if (item.itemContent?.qrcode) {
      // If the QR code exists, set it and open the dialog
      setQrCode(item.itemContent.qrcode);
      setDialogOpen(true);
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setQrCode(null);
  };

  return (
    <>
      <TopNav text={translate(language, 'rewards.myRewards')} />
      <Container fontFamily={fontFamilies.regular}>
        {loading ? (
          <RewardsList>
            {[...Array(4)].map((_, index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                height={120}
                style={{marginBottom: '10px'}}
              />
            ))}
          </RewardsList>
        ) : error ? (
          <p>
            {translate(language, 'rewards.error')} {error}
          </p>
        ) : rewards && rewards.length > 0 ? (
          <RewardsList>
            {rewards.map((reward, index) => (
              <RewardItem key={index} onClick={() => handleCardClick(reward)}>
                {reward.redeemItemSnapshot.image && (
                  <RewardImage
                    src={reward.redeemItemSnapshot.image}
                    alt={reward.redeemItemSnapshot.name[language]}
                  />
                )}
                <RewardText>
                  {reward.redeemItemSnapshot.name[language]}
                </RewardText>
                <RewardText>
                  {reward.quantity} {translate(language, 'rewards.quantity')}
                </RewardText>
                <RewardText>
                  {new Date(reward.created).toLocaleString('en-US', {
                    timeZone: 'Asia/Hong_Kong',
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                  })}
                </RewardText>
                <RewardText>
                  {translate(language, 'rewards.status')}: {reward.status}
                </RewardText>
              </RewardItem>
            ))}
          </RewardsList>
        ) : (
          <Container fontFamily={fontFamilies.regular}>
            <p>{translate(language, 'rewards.noRewardsFound')}</p>
            <ActionButton
              fontFamily={fontFamilies.semiBold}
              onClick={() =>
                navigate('/explorer', {state: {showBackIcon: false}})
              }>
              {translate(language, 'rewards.viewCoupons')}
            </ActionButton>
          </Container>
        )}
      </Container>
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>
          {qrCode &&
            rewards.find(reward => reward.itemContent?.qrcode === qrCode)
              ?.redeemItemSnapshot.name[language]}
        </DialogTitle>
        <DialogContent>
          {qrCode && (
            <QRCode
              value={qrCode}
              size={256}
              style={{display: 'block', margin: '20px auto'}}
            />
          )}
          <Button onClick={handleCloseDialog}>
            {translate(language, 'close')}
          </Button>
        </DialogContent>
      </Dialog>
      <BottomNav />
    </>
  );
};

export default Rewards;
