import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import {useContext, useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {LanguageContext} from '../contexts/LanguageProvider';
import {useFontFamilyByLanguage} from '../hooks/useFontFamilyByLanguage';
import {translate} from '../utils/translate';
import styled from 'styled-components';

// Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  font-family: ${props => props.fontFamily};
  height: 80vh;
`;

const Text = styled.div`
  font-family: ${props => props.fontFamily};
  margin: 20px 0;
`;

const SubText = styled.div`
  font-family: ${props => props.fontFamily};
  margin: 10px 0;
`;

const UserInput = styled.input`
  width: 100%;
  height: 40px;
  margin: 10px 0;
  padding: 0px 16px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 20px;
  background-color: #ffffff;
  font-family: ${props => props.fontFamily};
`;

const Link = styled.div`
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
  margin-top: 15px;
  font-family: ${props => props.fontFamily};
`;

const CheckboxContainer = styled.div`
  align-self: flex-start;
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const Checkbox = styled.input`
  margin-right: 8px;
`;

const LoginButtonStyled = styled.button`
  width: 100%;
  height: 40px;
  margin-top: 20px;
  padding: 0px 16px;
  border: none;
  border-radius: 20px;
  background-color: #3ddbd1;
  color: #ffffff;
  font-family: ${props => props.fontFamily};
  cursor: pointer;
`;

// Component for Login Button
const LoginButton = ({onClick, text, fontFamily}) => {
  return (
    <LoginButtonStyled fontFamily={fontFamily} onClick={onClick}>
      {text}
    </LoginButtonStyled>
  );
};

const tutorialImages = [
  `${process.env.REACT_APP_CDN_URI}/cma-${process.env.REACT_APP_ENV}/tutorial1.png`,
  `${process.env.REACT_APP_CDN_URI}/cma-${process.env.REACT_APP_ENV}/tutorial2.png`,
  `${process.env.REACT_APP_CDN_URI}/cma-${process.env.REACT_APP_ENV}/tutorial3.png`,
  `${process.env.REACT_APP_CDN_URI}/cma-${process.env.REACT_APP_ENV}/tutorial4.png`,
  `${process.env.REACT_APP_CDN_URI}/cma-${process.env.REACT_APP_ENV}/tutorial5.png`,
  `${process.env.REACT_APP_CDN_URI}/cma-${process.env.REACT_APP_ENV}/tutorial6.png`,
  `${process.env.REACT_APP_CDN_URI}/cma-${process.env.REACT_APP_ENV}/tutorial7.png`,
  `${process.env.REACT_APP_CDN_URI}/cma-${process.env.REACT_APP_ENV}/tutorial8.png`,
];

const Login = props => {
  const navigate = useNavigate();
  const {fontFamilies} = useFontFamilyByLanguage();
  const {language} = useContext(LanguageContext);

  const [email, setEmail] = useState(
    localStorage.getItem('rememberEmail') || '',
  );
  const [password, setPassword] = useState(
    localStorage.getItem('rememberPassword') || '',
  );
  const [rememberMe, setRememberMe] = useState(
    localStorage.getItem('rememberMe') === 'true',
  );
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (rememberMe) {
      localStorage.setItem('rememberEmail', email);
      localStorage.setItem('rememberPassword', password);
    } else {
      localStorage.removeItem('rememberEmail');
      localStorage.removeItem('rememberPassword');
    }
  }, [rememberMe, email, password]);

  const defaultProps = {
    text: translate(language, 'login.welcomeBack'),
    subText: translate(language, 'login.signInToAccess'),
    userInput: translate(language, 'login.email'),
    userPassword: translate(language, 'login.password'),
  };

  const validateEmail = email => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\\.,;:\s@"]+\.)+[^<>()[\\.,;:\s@"]{2,})$/i;
    return re.test(String(email).toLowerCase());
  };

  const handleLogin = async () => {
    setError('');
    setLoading(true);

    // Validate the email format
    if (!validateEmail(email)) {
      setError(translate(language, 'login.invalidEmail'));
      setLoading(false);
      return;
    }

    try {
      // Make the login request to the backend
      const response = await axios.post(
        `${process.env.REACT_APP_API_URI}/api/auth/login`,
        {
          userEmail: email,
          userPassword: password,
        },
        {withCredentials: true}, // Ensure cookies are sent with the request
      );

      // Check if the login was successful (HTTP 200)
      if (response.status === 200) {
        // Handle "Remember Me" functionality (only email and password)
        if (rememberMe) {
          localStorage.setItem('rememberEmail', email);
          localStorage.setItem('rememberPassword', password);
          localStorage.setItem('rememberMe', 'true');
        } else {
          localStorage.removeItem('rememberEmail');
          localStorage.removeItem('rememberPassword');
          localStorage.removeItem('rememberMe');
        }

        // Navigate to the home page
        navigate('/');
      } else {
        setError('Login failed. Unexpected response.');
      }
    } catch (error) {
      console.error('Login error:', error);
      setError(
        error.response?.data?.message || 'Failed to login. Please try again.',
      );
    } finally {
      setLoading(false);
    }
  };

  const handleBiometricLogin = async () => {
    const base64CredentialId = localStorage.getItem('credentialId');
    if (!base64CredentialId) {
      alert(
        'No biometric credentials registered. Please enable biometric login in settings.',
      );
      return;
    }

    try {
      setLoading(true);
      const credentialId = Uint8Array.from(atob(base64CredentialId), c =>
        c.charCodeAt(0),
      );

      const publicKey = {
        challenge: new Uint8Array([
          ...window.crypto.getRandomValues(new Uint8Array(32)),
        ]),
        allowCredentials: [{type: 'public-key', id: credentialId}],
        userVerification: 'required',
        timeout: 60000,
      };

      const assertion = await navigator.credentials.get({publicKey});
      const response = await axios.post(
        `${process.env.REACT_APP_API_URI}/api/auth/biometric_verify`,
        {
          id: assertion.id,
          rawId: btoa(String.fromCharCode(...new Uint8Array(assertion.rawId))),
          type: assertion.type,
          response: {
            clientDataJSON: btoa(
              String.fromCharCode(
                ...new Uint8Array(assertion.response.clientDataJSON),
              ),
            ),
            authenticatorData: btoa(
              String.fromCharCode(
                ...new Uint8Array(assertion.response.authenticatorData),
              ),
            ),
            signature: btoa(
              String.fromCharCode(
                ...new Uint8Array(assertion.response.signature),
              ),
            ),
            userHandle: btoa(
              String.fromCharCode(
                ...new Uint8Array(assertion.response.userHandle || []),
              ),
            ),
          },
        },
      );
      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem(
          'userInfo',
          JSON.stringify(response.data.userInfo),
        );
        navigate('/');
      } else {
        setError(translate(language, 'login.serverAuthenticationFailed'));
      }
    } catch (error) {
      console.error('Biometric login error:', error);
      alert(error);
      setError(
        translate(language, 'login.biometricFailed') +
          (error.response?.data?.message ||
            translate(language, 'login.tryAgain')),
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Container fontFamily={fontFamilies.regular}>
        <Text fontFamily={fontFamilies.bold}>
          {props.text ?? defaultProps.text}
        </Text>
        <SubText fontFamily={fontFamilies.regular}>
          {props.subText ?? defaultProps.subText}
        </SubText>
        {error && <div style={{color: 'red'}}>{error}</div>}
        <UserInput
          fontFamily={fontFamilies.light}
          type="text"
          placeholder={props.userInput ?? defaultProps.userInput}
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <UserInput
          fontFamily={fontFamilies.light}
          type="password"
          placeholder={props.userPassword ?? defaultProps.userPassword}
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
        <CheckboxContainer>
          <Checkbox
            type="checkbox"
            id="rememberMe"
            checked={rememberMe}
            onChange={e => setRememberMe(e.target.checked)}
          />
          <label htmlFor="rememberMe">Remember me</label>
        </CheckboxContainer>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <LoginButton
              onClick={handleLogin}
              text={translate(language, 'login.logIn')}
              fontFamily={fontFamilies.semiBold}
            />
            <LoginButton
              onClick={handleBiometricLogin}
              text={translate(language, 'login.biometricLogin')}
              fontFamily={fontFamilies.semiBold}
            />
          </>
        )}
        <Link
          onClick={() =>
            navigate('/forgotPassword', {state: {showBackIcon: true}})
          }>
          {translate(language, 'login.forgotPassword')}
        </Link>
        <Link
          onClick={() => navigate('/signup', {state: {showBackIcon: true}})}>
          {translate(language, 'login.notAMember')}
        </Link>
      </Container>
      {/* Preloading tutorial images */}
      {tutorialImages.map(src => (
        <img key={src} src={src} style={{display: 'none'}} alt="tutorial" />
      ))}
    </>
  );
};

export default Login;
