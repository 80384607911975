// components/ProtectedRoute.jsx
import {useEffect, useState} from 'react';
import {Navigate} from 'react-router-dom';
import {isAuthenticated} from '../utils/auth';
import SkeletonLoader from './SkeletonLoader'; // Import the SkeletonLoader component

const ProtectedRoute = ({children}) => {
  const [authStatus, setAuthStatus] = useState('checking'); // 'checking', 'authenticated', 'not-authenticated'

  useEffect(() => {
    const verifyAuth = async () => {
      const isValid = await isAuthenticated();
      setAuthStatus(isValid ? 'authenticated' : 'not-authenticated');
    };

    verifyAuth();
  }, []);

  if (authStatus === 'checking') {
    return <SkeletonLoader />; // Display the skeleton loader when checking authentication
  }

  return authStatus === 'authenticated' ? children : <Navigate to="/login" />;
};

export default ProtectedRoute;
