import ReactDOM from 'react-dom/client';
import App from './App';
import ErrorBoundary from './components/ErrorBoundary';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router} from 'react-router-dom';
import styled from 'styled-components';
import React, {useEffect, useState} from 'react';

// Styled-components for iframe
const IframeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden; // Hide any overflow
  touch-action: manipulation; // Enables touch interaction like mobile devices
`;

const Iframe = styled.iframe`
  width: 375px; // Typical width of mobile view
  height: 812px; // Typical height of mobile view
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); // Add some shadow for aesthetics
  overflow: hidden; // Hide internal iframe scrollbar
  touch-action: manipulation; // Ensure mobile-like interactions such as gestures
  -ms-overflow-style: none; // Hide scroll bar in Internet Explorer and Edge
  scrollbar-width: none; // Hide scroll bar in Firefox
  touch-action: manipulation;

  &::-webkit-scrollbar {
    display: none; // Hide scroll bar in WebKit-based browsers like Chrome, Safari
  }
`;

// Main Wrapper component to handle screen width check
const AppWrapper = () => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 680);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 680);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (!isMobileView) {
    return (
      <IframeWrapper>
        <Iframe src="/" title="Mobile App View" />
      </IframeWrapper>
    );
  }

  return (
    <ErrorBoundary>
      <Router>
        <App />
      </Router>
    </ErrorBoundary>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<AppWrapper />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
reportWebVitals();
