import {useContext, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import styled from 'styled-components';
import BottomNav from '../Navigation/BottomNav';
import TopNav from '../Navigation/TopNav';
import {LanguageContext} from '../contexts/LanguageProvider';
import {useFontFamilyByLanguage} from '../hooks/useFontFamilyByLanguage';
import {translate} from '../utils/translate';
import MyLikes from './MyLikes';
import MyRewards from './MyRewards';

// Styled Components
const PageContainer = styled.div`
  font-family: ${props => props.fontFamily};
  text-align: center;
  color: #333;
  padding: 20px;
  max-width: 400px;
  margin: auto;
`;

const NavTabs = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
`;

const NavButton = styled.button`
  font-family: ${props => props.fontFamily};
  background: none;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-bottom: ${props =>
    props.active ? '3px solid #3DDBD1' : '3px solid transparent'};
`;

const MainContent = styled.div`
  margin: 20px 0;
`;

const ArticlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ArticleCard = styled.div`
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;

const ArticleImage = styled.img`
  width: 100%;
  border-radius: 10px;
`;

const ArticleTitle = styled.h2`
  font-family: ${props => props.fontFamily};
  font-size: 16px;
  margin-top: 10px;
`;

const SavedPage = () => {
  const location = useLocation();
  const {fontFamilies} = useFontFamilyByLanguage();
  const {language} = useContext(LanguageContext);
  const [activeTab, setActiveTab] = useState('coupons');

  useEffect(() => {
    const savedTab = localStorage.getItem('activeTab');
    if (savedTab) {
      setActiveTab(savedTab);
    } else if (location.state && location.state.activeTab) {
      setActiveTab(location.state.activeTab);
    }
  }, [location.state]);

  const handleSetActiveTab = tab => {
    setActiveTab(tab);
    localStorage.setItem('activeTab', tab);
  };

  const MyCoupons = () => <MyRewards type="coupon" />;
  const MyEarnings = () => <MyRewards type="earn" />;

  return (
    <>
      <TopNav text={translate(language, 'savedPage.saved')} />
      <PageContainer fontFamily={fontFamilies.regular}>
        <NavTabs>
          <NavButton
            fontFamily={fontFamilies.semiBold}
            active={activeTab === 'coupons'}
            onClick={() => handleSetActiveTab('coupons')}>
            {translate(language, 'savedPage.myCoupons')}
          </NavButton>
          <NavButton
            fontFamily={fontFamilies.semiBold}
            active={activeTab === 'likes'}
            onClick={() => handleSetActiveTab('likes')}>
            {translate(language, 'savedPage.myLikes')}
          </NavButton>
        </NavTabs>

        <MainContent>
          {activeTab === 'coupons' && <MyCoupons />}
          {activeTab === 'likes' && (
            <ArticlesContainer>
              <MyLikes translate={translate} language={language} />
            </ArticlesContainer>
          )}
          {activeTab === 'earnings' && <MyEarnings />}
        </MainContent>
      </PageContainer>
      <BottomNav />
    </>
  );
};

export default SavedPage;
